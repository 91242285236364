import { appUsage } from '../../types/appUsage';
import { actionInterface } from '../../types/common';
import { APP_USAGE_TYPES } from '../actionTypes/appUsage';

const defaultState: appUsage = {
  quickStatsBlock: {
    header: 'Quick stats',
    data: [
      {
        value: 0,
        text: 'Total users',
      },
      {
        value: 0,
        text: 'Total active users',
      },
      {
        value: 0,
        text: 'Av. daily active users',
      },
      {
        value: 0,
        text: 'App stickiness',
      },
    ],
  },

  churnBlock: {
    header: 'Churn',
    data: [
      {
        text: 'Overall churn rate',
        value: 0.0,
      },
      {
        text: 'Nutrition specific',
        value: 0.0,
      },
      {
        text: 'Fitness specific',
        value: 0.0,
      },
      {
        text: 'Sleep specific',
        value: 0.0,
      },
    ],
  },

  userJourney: {
    header: 'User journey',
    activeBtn: 'all',
    activationChurn: [
      {
        text: 'Total users',
        value: 0,
      },
      {
        text: 'Onboarding started (part 1)',
        value: 0,
      },
      {
        text: 'Onboarding completed (part 1)',
        value: 0,
      },
      {
        text: 'Onboarding face scan completed',
        value: 0,
      },
      {
        text: 'Onboarding started (part 2)',
        value: 0,
      },
      {
        text: 'Onboarding completed (part 2)',
        value: 0,
      },
      {
        text: 'Active users',
        value: 0,
      },
    ],
    programChurn: [
      {
        text: 'Total users',
        value: 0,
      },
      {
        text: 'Week 1 interaction',
        value: 0,
      },
      {
        text: 'Week 1 challenge completion',
        value: 0,
      },
      {
        text: 'Week 2 interaction',
        value: 0,
      },
      {
        text: 'Week 2 challenge completion',
        value: 0,
      },
      {
        text: 'Week 3 interaction',
        value: 0,
      },
      {
        text: 'Week 3 challenge completion',
        value: 0,
      },
      {
        text: 'Week 4 interaction',
        value: 0,
      },
      {
        text: 'Week 4 challenge completion',
        value: 0,
      },
      {
        text: 'Offboarding started',
        value: 0,
      },
      {
        text: 'Offboarding completed',
        value: 0,
      },
      {
        text: 'Feedback started',
        value: 0,
      },
      {
        text: 'Feedback completed',
        value: 0,
      },
    ],
  },
  isPercent: false,
};

export default function appUsageReducer(state: appUsage = defaultState, { data, type }: actionInterface) {
  switch (type) {
    case APP_USAGE_TYPES.GET_ALL_STATS: {
      return {
        ...state,
        quickStatsBlock: {
          ...state.quickStatsBlock,
          data: [
            {
              value: data.totalUsers,
              text: 'Total users',
            },
            {
              value: data.totalActiveUsers,
              text: 'Total active users',
            },
            {
              value: data.activeToday,
              text: 'Av. daily active users',
            },
            {
              value: data.stickness,
              text: 'App stickiness',
            },
          ],
        },
        churnBlock: {
          header: 'Churn',
          data: [
            {
              text: 'Overall churn rate',
              value: data.churn,
            },
            {
              text: 'Nutrition specific',
              value: data.churnNutrition,
            },
            {
              text: 'Fitness specific',
              value: data.churnFitness,
            },
            {
              text: 'Sleep specific',
              value: data.churnSleep,
            },
          ],
        },
        userJourney: {
          ...state.userJourney,
          activationChurn: [
            {
              text: 'Total users',
              value: data.totalUsers,
            },
            {
              text: 'Onboarding started (part 1)',
              value: data.onboardingStarted1,
            },
            {
              text: 'Onboarding completed (part 1)',
              value: data.onboardingCompleted1,
            },
            {
              text: 'Onboarding face scan completed',
              value: data.onboardingFaceScan,
            },
            {
              text: 'Onboarding started (part 2)',
              value: data.onboardingStarted2,
            },
            {
              text: 'Onboarding completed (part 2)',
              value: data.onboardingCompleted2,
            },
            {
              text: 'Active users',
              value: data.totalActiveUsers,
            },
          ],
          programChurn: [
            {
              text: 'Total users',
              value: data.totalUsersProgramme,
            },
            {
              text: 'Week 1 interaction',
              value: data.week1ChallengeInteraction,
            },
            {
              text: 'Week 1 challenge completion',
              value: data.week1ChallengeCompletion,
            },
            {
              text: 'Week 2 interaction',
              value: data.week2ChallengeInteraction,
            },
            {
              text: 'Week 2 challenge completion',
              value: data.week2ChallengeCompletion,
            },
            {
              text: 'Week 3 interaction',
              value: data.week2ChallengeInteraction,
            },
            {
              text: 'Week 3 challenge completion',
              value: data.week3ChallengeCompletion,
            },
            {
              text: 'Week 4 interaction',
              value: data.week2ChallengeInteraction,
            },
            {
              text: 'Week 4 challenge completion',
              value: data.week4ChallengeCompletion,
            },
            {
              text: 'Offboarding started',
              value: data.offboardingStarted,
            },
            {
              text: 'Offboarding completed',
              value: data.offboardingCompleted,
            },
            {
              text: 'Feedback started',
              value: data.feedbackStarted,
            },
            {
              text: 'Feedback completed',
              value: data.feedbackCompleted,
            },
          ],
        },
      };
    }

    case APP_USAGE_TYPES.USER_JOURNEY_TO_PERCENT: {
      return { ...state, isPercent: !state.isPercent };
    }

    case APP_USAGE_TYPES.SET_ACTIVE_FILTER_BUTTON: {
      return {
        ...state,
        userJourney: {
          ...state.userJourney,
          activeBtn: data,
        },
      };
    }
  }

  return state;
}
