import React from 'react';
import LineBar from '../MostPopularContentTypes/LineBar/LineBar';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Behavior } from '../../../types/behavior';

const ComponentLayoutPrompts = () => {
  const { prompts, promptsValues, notificationsValues, notifications } = useSelector<ApplicationState, Behavior>(
    (state) => state.behavior
  );

  return (
    <>
      <div className={'component_layout_notification'}>
        <h3>Notification engagement</h3>
        <div className={'notification__wrapper'}>
          <div className={'notification-data'}>
            <h4>Top 3 clicked on notifications</h4>
            <ul className="notification-data__list">
              {notifications.map((notification, index) => {
                return (
                  <li key={index} className="notification-data__item">
                    {notification}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={'notification-chart'}>
            <p className={'notification-chart__ratio'}>Sent vs answered notifications ratio total</p>
            {notificationsValues.map(({ value, title }, index) => {
              return <LineBar key={index} text={title} value={value} />;
            })}
          </div>
        </div>
      </div>

      <div className={'component_layout_prompt'}>
        <h3>Prompt card engagement</h3>
        <div className={'prompt__wrapper'}>
          <div className={'prompt-data'}>
            <h4>Top 3 clicked on prompt cards</h4>
            <ul className="prompt-data__list">
              {prompts.map((notification, index) => {
                return (
                  <li key={index} className="prompt-data__item">
                    {notification}
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={'prompt-chart'}>
            <p className={'prompt-chart__ratio'}>Sent vs answered notifications ratio total</p>
            {promptsValues.map(({ value, title }, index) => {
              return <LineBar key={index} text={title} value={value} />;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ComponentLayoutPrompts;
