import React, { useMemo, useState } from 'react';
import './Compliance.scss';
import ComplianceItem from '../../components/_common/ComplianceItem/ComplianceItem';
// import FaceScanning from '../../components/FaceScanning/FaceScanning/FaceScanning';
import mood from '../../assets/static/svg/mood.svg';
import sleepQuality from '../../assets/static/svg/sleep_ quality.svg';
import sleepDuration from '../../assets/static/svg/sleep_duration.svg';
import ComponentLayout from '../../components/_common/ComponentLayout/ComponentLayout';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { appUsage } from '../../types/appUsage';
import { calculateComplianceGraph, generateGraphValues } from '../../Utils/Compliance/MoodGraph';
import { ComplianceState } from '../../store/reducers/complianceReducer';

const CompliancePage = () => {
  const { data } = useSelector<ApplicationState, ComplianceState>((state) => state.compliance);
  const { quickStatsBlock } = useSelector<ApplicationState, appUsage>((state) => state.appUsage);
  const [blockLoading, setBlockLoading] = useState(false);

  const totalActiveUsers = useMemo(() => quickStatsBlock.data[1].value, [quickStatsBlock]);

  return (
    <div className={'compliance'}>
      <ComponentLayout
        sectionName={'compliance'}
        title={'Compliance'}
        daily={'daily'}
        weekly={'weekly'}
        full_program={'full programme'}
        setBlockLoading={setBlockLoading}
      >
        <div className={'compliance__itemWrapper'}>
          <ComplianceItem
            imgSrc={mood}
            title={'Mood'}
            number={calculateComplianceGraph(data, 'mood', totalActiveUsers)}
            text={'across the programme'}
            graphData={generateGraphValues(data, 'mood')}
            blockLoading={blockLoading}
          />
          <ComplianceItem
            imgSrc={sleepQuality}
            title={'Sleep quality'}
            number={calculateComplianceGraph(data, 'sleepD', totalActiveUsers)}
            text={'across the programme'}
            graphData={generateGraphValues(data, 'sleepQ')}
            blockLoading={blockLoading}
          />
          <ComplianceItem
            imgSrc={sleepDuration}
            title={'Sleep duration'}
            number={calculateComplianceGraph(data, 'sleepQ', totalActiveUsers)}
            text={'across the programme'}
            graphData={generateGraphValues(data, 'sleepD')}
            blockLoading={blockLoading}
          />
        </div>
        {/*<FaceScanning />*/}
        <div className={'demographics_difference '}>
          <ComplianceItem
            title={'Scan engagement different between demographics '}
            graphData={generateGraphValues(data, 'scan')}
            number={calculateComplianceGraph(data, 'scan', totalActiveUsers)}
            text={'across the programme'}
            blockLoading={blockLoading}
          />
        </div>
      </ComponentLayout>
    </div>
  );
};

export default React.memo(CompliancePage);
