import { APP_USAGE_TYPES, INTER_ACTION_ACTION_TYPES } from '../actionTypes/appUsage';
import { AppUsageData } from '../../types/appUsage';
import { HttpsCallableResult } from 'firebase/functions';

export function actionSetActive(data: string) {
  return {
    type: INTER_ACTION_ACTION_TYPES.SET_ACTIVE,
    data,
  };
}

export function actionSetAppUsageStats(data: AppUsageData) {
  return {
    type: APP_USAGE_TYPES.GET_ALL_STATS,
    data,
  };
}

export function actionSetJourneyDataToPercent() {
  return {
    type: APP_USAGE_TYPES.USER_JOURNEY_TO_PERCENT,
  };
}

export function actionSetActiveFilterButton(data: string) {
  return {
    type: APP_USAGE_TYPES.SET_ACTIVE_FILTER_BUTTON,
    data,
  };
}
