import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Routes from './config/routes/routes';
import LogIn from './pages/LogIn/LogIn';
import Layout from './components/_common/Layout/Layout';
import AppUsage from './pages/AppUsage/AppUsage';
import Compliance from './pages/Compilance/Compliance';
import Content from './pages/Content/Content';
import Behavior from './pages/Behavior/Behavior';
import userIsAuthenticated from './services/checkAutorisation';

function AppRouter() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path={Routes.LOGIN} component={LogIn} />
          <Layout>
            <Route path={Routes.APP_USAGE} component={userIsAuthenticated(AppUsage)} />
            <Route path={Routes.COMPLIANCE} component={userIsAuthenticated(Compliance)} />
            <Route path={Routes.CONTENT} component={userIsAuthenticated(Content)} />
            <Route path={Routes.BEHAVIOR} component={userIsAuthenticated(Behavior)} />
          </Layout>
        </Switch>
      </Router>
    </>
  );
}

export default AppRouter;
