import { actionInterface } from '../../types/common';
import { DateTypes } from '../../types/date';
import DATE_ACTION_TYPES from '../actionTypes/date';

const startDate: Date = new Date('02/14/2022');
const defaultState: DateTypes = {
  interactionTime: [startDate, new Date()],
  compliance: [startDate, new Date()],
  contentEngagement: [startDate, new Date()],
  behaviour: [startDate, new Date()],
};

export default function dateReducer(state = defaultState, { data, type }: actionInterface) {
  const isDay = data?.length === 2;
  const arrayFromActionData = [data];

  switch (type) {
    case DATE_ACTION_TYPES.SET_INTERACTION_TIME_DATE: {
      return {
        ...state,
        interactionTime: [...data],
      };
    }

    case DATE_ACTION_TYPES.SET_COMPLIANCE_DATE: {
      return {
        ...state,
        compliance: isDay ? [...data] : [...arrayFromActionData],
      };
    }

    case DATE_ACTION_TYPES.SET_CONTENT_ENGAGEMENT_DATE: {
      return {
        ...state,
        contentEngagement: isDay ? [...data] : [...arrayFromActionData],
      };
    }

    case DATE_ACTION_TYPES.SET_BEHAVIOUR_DATE: {
      return {
        ...state,
        behaviour: [...data],
      };
    }
  }

  return state;
}
