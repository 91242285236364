import { actionInterface } from '../../types/common';
import DASHBOARD_ACTION_TYPES from '../actionTypes/dashboard';
import { dataFromFirebase } from '../../types/appUsage';

const defaultState: dataFromFirebase = {
  usersData: [],
};

export default function dashboardReducer(state = defaultState, { data, type }: actionInterface) {
  switch (type) {
    case DASHBOARD_ACTION_TYPES.SET_DATA: {
      return {
        ...state,
        usersData: [...data],
      };
    }
  }

  return state;
}
