import React, { useState } from 'react';
import HeaderElement from '../_common/Headers/HeaderElement/HeaderElement';
import Button from '../_common/Button/Button';
import './UserJourney.scss';
import LineBarItem from '../_common/LineBarItem/LineBarItem';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { appUsage } from '../../types/appUsage';
import Filters from '../Filters/Filters';
import { LoadingState } from '../../types/loading';
import Skeleton from '../_common/Skeleton/Skeleton';

interface Props {
  activation?: string;
  program?: string;
}

const UserJourney: React.FC<Props> = ({ activation, program }) => {
  const { userJourney, isPercent } = useSelector<ApplicationState, appUsage>((state) => state.appUsage);
  const { isLoading } = useSelector<ApplicationState, LoadingState>((state) => state.loading);
  const [isSectionLoading, setIsSectionLoading] = useState(false);
  const { programChurn, activationChurn, header } = userJourney;
  const [activeButton, setActiveButton] = useState('activation_churn');
  const clickHandler = (id: string) => {
    setActiveButton(id);
  };

  return (
    <>
      <HeaderElement isPercent={isPercent} text={header} textClassName={'pClass'} value={'123'}>
        <div className={'btn_state'}>
          {activation && (
            <Button
              id={'activation_churn'}
              className={activeButton === 'activation_churn' ? 'white active' : 'white'}
              onClickWithEvent={(event) => {
                clickHandler(event.target.id);
              }}
              title={'activation churn'}
            />
          )}
          {program && (
            <Button
              id={'program_churn'}
              className={activeButton === 'program_churn' ? 'white active' : 'white'}
              onClickWithEvent={(event) => {
                clickHandler(event.target.id);
              }}
              title={'program churn'}
            />
          )}
        </div>
      </HeaderElement>
      <div className={'userJourney__data'}>
        {isLoading ? (
          <Skeleton style={{ left: '50%', transform: 'translateX(-50%)' }} />
        ) : (
          <>
            {activeButton === 'program_churn' && (
              <>
                <Filters setIsLoading={setIsSectionLoading} />
                {isSectionLoading ? (
                  <Skeleton style={{ left: '50%', transform: 'translateX(-50%)' }} />
                ) : (
                  programChurn.map(({ text, value }, index) => {
                    return <LineBarItem isPercent={isPercent} key={index} title={text} value={value} />;
                  })
                )}
              </>
            )}
            {activeButton === 'activation_churn' &&
              activationChurn.map(({ text, value }, index) => {
                return <LineBarItem isPercent={isPercent} key={index} title={text} value={value} />;
              })}
          </>
        )}
      </div>
    </>
  );
};

export default UserJourney;
