import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
import Routes from '../config/routes/routes';
import USER_TOKEN_NAMESPACE from '../constants/namespace';

const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: Routes.LOGIN,
  authenticatedSelector: () => !!localStorage.getItem(USER_TOKEN_NAMESPACE),
  wrapperDisplayName: 'UserIsAuthenticated',
  allowRedirectBack: true,
});

export default userIsAuthenticated;
