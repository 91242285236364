import { actionInterface } from '../../types/common';
import { LoadingState } from '../../types/loading';
import LOADING_ACTION_TYPES from '../actionTypes/loading';

const defaultData = {
  isLoading: false,
};

export default function loadingReducer(state: LoadingState = defaultData, { data, type }: actionInterface) {
  switch (type) {
    case LOADING_ACTION_TYPES.SET_IS_LOADING:
      return {
        isLoading: data,
      };
  }

  return state;
}
