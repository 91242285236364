import Events from '../../constants/events';
import { Document } from '../../types/data';
import firebase from 'firebase/compat';
import { Programme } from '../../constants/filter';

const Timestamp = firebase.firestore.Timestamp;
const startDateTimestamp = Date.parse('2022-02-13T23:59:59');
const oneDay = 1000 * 60 * 60 * 24;

// export const currentActiveUsers = (users: Document[]) => {
//   const openedAppUsers = users.filter((user) => user.event === Events.OpenedApp);
//
//   const result = openedAppUsers.filter((user) => {
//     const userTimestamp = user.timestamp.seconds * 1000;
//     const currentTimestamp = Timestamp.now().seconds * 1000;
//
//     const userDate = new Date(userTimestamp);
//     const currentDate = new Date(currentTimestamp);
//
//     const humanDateFormat1 = userDate.toLocaleDateString().slice(0, 10);
//     const humanDateFormat2 = currentDate.toLocaleDateString().slice(0, 10);
//
//     return humanDateFormat1 === humanDateFormat2;
//   });
//
//   return result.length;
// };

export const totalUsers = (users: Document[]) => {
  return users.filter((user) => user.event === Events.Registered).length;
};

export const totalActiveUsers = (users: Document[], goal?: Programme) => {
  const openedAppUsers = users.filter((user) => user.event === Events.OpenedApp);

  const result = openedAppUsers.filter((user) => {
    const userTimestamp = user.timestamp.seconds * 1000;

    if (goal) {
      return userTimestamp >= startDateTimestamp && user.programme === goal;
    }

    return userTimestamp >= startDateTimestamp;
  });

  return result;
};

export const averageDailyUsers = (users: Document[], goal?: Programme) => {
  const currentTimestamp = Timestamp.now().seconds * 1000;

  const diffInTime = currentTimestamp - startDateTimestamp;

  const diffInDays = Math.round(diffInTime / oneDay);

  const totalUsers = totalActiveUsers(users, goal).length;

  return diffInDays > 0 ? Math.round(totalUsers / diffInDays) : totalUsers;
};

export const appStickiness = (users: Document[]) =>
  (averageDailyUsers(users) / totalActiveUsers(users).length) * 100 || 0;

export const generateQuickStatsData = (users: Document[]) => {
  return {
    totalUsers: totalUsers(users),
    totalActiveUsers: totalActiveUsers(users).length,
    averageDailyUsers: averageDailyUsers(users),
    appStickiness: appStickiness(users),
  };
};
