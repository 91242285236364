import { DashboardFilter } from '../types/dashboard';
import useFirebaseFunctions from './useFirebaseFunctions';
import { useDispatch } from 'react-redux';
import actionSetLoadingStatus from '../store/actions/loadingActions';

function useData() {
  const { getUpdatedContent } = useFirebaseFunctions();
  const dispatch = useDispatch();

  const loadData = async (filterData: DashboardFilter) => {
    dispatch(actionSetLoadingStatus(true));
    await getUpdatedContent(filterData);
    dispatch(actionSetLoadingStatus(false));
  };

  return { loadData };
}

export default useData;
