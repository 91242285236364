import React from 'react';
import './Button.scss';

interface Props {
  className?: string;
  type?: any;
  disabled?: boolean;
  children?: any;
  title?: string;
  onClick?: any;
  onClickWithEvent?: (event: any) => void;
  id?: string;
  name?: string;
  value?: string;
}

const Button = (Props: Props) => {
  const { className, type, disabled, title, onClick, onClickWithEvent, id, name, children, value } = Props;

  return (
    <button
      className={className ? className : 'form__btn'}
      type={type}
      disabled={disabled}
      onClick={onClick || onClickWithEvent}
      id={id}
      name={name}
      value={value}
    >
      {children}
      {title}
    </button>
  );
};

export default Button;
