import React from 'react';
import LogInForm from '../../components/LogInForm/LogInForm';
import './LogIn.scss';
import SVG_ICONS from '../../assets/static/svg/svg';

const LogIn = () => {
  return (
    <div className={'login__wrapper'}>
      <div className={'login__header'}>
        <img src={SVG_ICONS.ASPIRE_PRIMARY} alt={'logo'} />
      </div>
      <div className={'login__content'}>
        <LogInForm />
      </div>
    </div>
  );
};

export default LogIn;
