import { Content, ResContent } from '../../types/contentTypes';
import CONTENT_ACTION_TYPES from '../actionTypes/content';

export interface CustomAction<T> {
  type: string;
  data: T;
}

const defaultState: Content = {
  data: [
    {
      title: 'Recipes',
      titleValue: 0,
      data: [
        {
          text: 'Opened',
          value: 0,
        },
        {
          text: 'Completed',
          value: 0,
        },
      ],
    },
    {
      title: 'Tips prompted',
      titleValue: 0,
      data: [
        {
          text: 'Opened',
          value: 0,
        },
        {
          text: 'Completed',
          value: 0,
        },
      ],
    },
    {
      title: 'Articles',
      titleValue: 0,
      data: [
        {
          text: 'Opened',
          value: 0,
        },
        {
          text: 'Completed',
          value: 0,
        },
      ],
    },
    {
      title: 'Challenges prompted',
      titleValue: 0,
      data: [
        {
          text: 'Opened',
          value: 0,
        },
        {
          text: 'Completed',
          value: 0,
        },
      ],
    },
  ],
  contentItems: {
    dataRecipes: [
      {
        label: '',
        title: '',
        text: '',
      },
      {
        label: '',
        title: '',
        text: '',
      },
      {
        label: '',
        title: '',
        text: '',
      },
    ],

    dataArticles: [
      {
        label: '',
        title: '',
        text: '',
      },
      {
        label: '',
        title: '',
        text: '',
      },
      {
        label: '',
        title: '',
        text: '',
      },
    ],
  },
};

export default function contentReducer(state: Content = defaultState, action: CustomAction<ResContent>) {
  switch (action.type) {
    case CONTENT_ACTION_TYPES.SET_CONTENT: {
      return {
        ...state,
        data: [
          {
            title: 'Recipes',
            titleValue: 30,
            data: [
              {
                text: 'Opened',
                value: action.data.recipesOpened,
              },
              {
                text: 'Completed',
                value: action.data.recipesCompleted,
              },
            ],
          },
          {
            title: 'Tips prompted',
            titleValue: 30,
            data: [
              {
                text: 'Opened',
                value: action.data.tipsOpened,
              },
              {
                text: 'Completed',
                value: action.data.tipsCompleted,
              },
            ],
          },
          {
            title: 'Articles',
            titleValue: 30,
            data: [
              {
                text: 'Opened',
                value: action.data.articlesOpened,
              },
              {
                text: 'Completed',
                value: action.data.articlesCompleted,
              },
            ],
          },
          {
            title: 'Challenges prompted · ',
            titleValue: 30,
            data: [
              {
                text: 'Opened',
                value: action.data.challengesO,
              },
              {
                text: 'Completed',
                value: action.data.challengesC,
              },
            ],
          },
        ],
        contentItems: {
          dataRecipes: action.data.dataRecipes,
          dataArticles: action.data.dataArticles,
        },
      };
    }
  }

  return state;
}
