import { createStore, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import appUsageReducer from './reducers/appUsageReducer';
import { appUsage, dataFromFirebase } from '../types/appUsage';
import { appUsageDefault } from '../types/default/appUsageDefaultTypes';
import complianceReducer, { ComplianceState } from './reducers/complianceReducer';
import { Content } from '../types/contentTypes';
import contentReducer from './reducers/contentReducer';
import { contents } from '../types/default/contentsTypes';
import behaviorReducer from './reducers/behaviorReducer';
import { Behavior } from '../types/behavior';
import dashboardReducer from './reducers/dashboardReducer';
import filterReducer from './reducers/filterReducer';
import dateReducer from './reducers/dateReducer';
import { DateTypes } from '../types/date';
import { DashboardFilter } from '../types/dashboard';
import { LoadingState } from '../types/loading';
import loadingReducer from './reducers/loadingReducer';

export interface ApplicationState {
  appUsage: appUsage;
  appUsageDefault: appUsageDefault;
  compliance: ComplianceState;
  content: Content;
  contents: contents;
  behavior: Behavior;
  dashboard: dataFromFirebase;
  filter: DashboardFilter;
  date: DateTypes;
  loading: LoadingState;
}

export const reducer = combineReducers({
  appUsage: appUsageReducer,
  compliance: complianceReducer,
  content: contentReducer,
  behavior: behaviorReducer,
  dashboard: dashboardReducer,
  filter: filterReducer,
  date: dateReducer,
  loading: loadingReducer,
});

export const store = createStore(reducer, composeWithDevTools());
