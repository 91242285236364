import { AgeGroup, Gender, Programme, Type, Vouchers } from './filter';
import { DashboardFilter } from '../types/dashboard';

export const voucherInputs = [
  {
    label: 'Aspire Partners',
    value: Vouchers.AspirePartners,
  },
  {
    label: 'Aspire Pepsi',
    value: Vouchers.AspirePepsi,
  },
  {
    label: 'Aspire Pepsi Extra',
    value: Vouchers.AspirePepsiExtra,
  },
  {
    label: 'Aspire Testers',
    value: Vouchers.AspireTesters,
  },
  {
    label: 'Aspire Testers Extra',
    value: Vouchers.AspireTestersExtra,
  },
  {
    label: 'India Partners',
    value: Vouchers.India_partners,
  },
  {
    label: 'India Pepsi Internal',
    value: Vouchers.India_pepsi_internal,
  },
  {
    label: 'India Testers',
    value: Vouchers.India_testers,
  },
  {
    label: 'India Externals',
    value: Vouchers.India_external,
  },
];
export const typeInputs = [
  {
    name: 'bioageType',
    id: Type.Overall,
    label: 'Overall',
    type: 'radio',
  },
  {
    name: 'bioageType',
    id: Type.Healthy_bio_age,
    label: 'Healthy initial bio age',
    type: 'radio',
  },
  {
    name: 'bioageType',
    id: Type.High_bio_age,
    label: 'High initial bio age',
    type: 'radio',
  },
];
export const genderInputs = [
  {
    label: 'Males',
    type: 'checkbox',
    name: 'gender',
    value: Gender.Male,
    id: Gender.Male,
  },
  {
    label: 'Females',
    type: 'checkbox',
    name: 'gender',
    value: Gender.Female,
    id: Gender.Female,
  },
];
export const ageInputs = [
  {
    label: 'All',
    type: 'radio',
    name: 'age',
    value: AgeGroup.All,
    id: AgeGroup.All,
  },
  {
    label: '<25',
    type: 'radio',
    name: 'age',
    value: AgeGroup.Less25,
    id: AgeGroup.Less25,
  },
  {
    label: '25-50',
    type: 'radio',
    name: 'age',
    value: AgeGroup.Between25_50,
    id: AgeGroup.Between25_50,
  },
  {
    label: '50-75',
    type: 'radio',
    name: 'age',
    value: AgeGroup.Between50_75,
    id: AgeGroup.Between50_75,
  },
  {
    label: '>75',
    type: 'radio',
    name: 'age',
    value: AgeGroup.More75,
    id: AgeGroup.More75,
  },
];
export const defaultFilterInputState: DashboardFilter = {
  bioageType: Type.Overall,
  gender: [Gender.Male, Gender.Female],
  voucherTypes: [Vouchers.AspireTestersExtra],
  age: AgeGroup.All,
  programme: Programme.All,
};
