import { ComplianceData } from '../../types/compliance';
import COMPLIANCE_ACTION_TYPES from '../actionTypes/compliance';

export interface CustomAction {
  type: string;
  data?: any;
}

export interface ComplianceState {
  data: ComplianceData;
}

const defaultState: ComplianceState = {
  data: {
    mood25M: 0,
    mood25F: 0,
    mood50M: 0,
    mood50F: 0,
    mood75M: 0,
    mood75F: 0,
    mood99M: 0,
    mood99F: 0,
    sleepQ25M: 0,
    sleepQ25F: 0,
    sleepQ50M: 0,
    sleepQ50F: 0,
    sleepQ75M: 0,
    sleepQ75F: 0,
    sleepQ99M: 0,
    sleepQ99F: 0,
    sleepD25M: 0,
    sleepD25F: 0,
    sleepD50M: 0,
    sleepD50F: 0,
    sleepD75M: 0,
    sleepD75F: 0,
    sleepD99M: 0,
    sleepD99F: 0,
    scan25M: 0,
    scan25F: 0,
    scan50M: 0,
    scan50F: 0,
    scan75M: 0,
    scan75F: 0,
    scan99M: 0,
    scan99F: 0,
  },
};

export default function complianceReducer(state = defaultState, action: CustomAction) {
  switch (action.type) {
    case COMPLIANCE_ACTION_TYPES.SET_COMPLIANCE_DATA:
      return {
        ...state,
        data: { ...action.data },
      };
  }

  return state;
}
