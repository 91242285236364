import React from 'react';
import './ModalFilter.scss';
import Button from '../_common/Button/Button';
import { ageInputs, genderInputs, typeInputs, voucherInputs } from '../../constants/defaultData';
import { Field, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { DashboardFilter } from '../../types/dashboard';
import useData from '../../hooks/useData';
import actionFilterDataSet from '../../store/actions/filterAction';

interface Props {
  btnPressed: () => void;
}

const ModalFilter = ({ btnPressed }: Props) => {
  const { filter } = useSelector((state: ApplicationState) => state);
  const { loadData } = useData();
  const dispatch = useDispatch();

  const renderVoucherInputs = (handleChange: any) =>
    voucherInputs.map(({ label, value }, index) => {
      return (
        <label className="container" key={index}>
          {label}
          <Field
            type="checkbox"
            id={value}
            name={'voucherTypes'}
            value={value}
            onChange={handleChange('voucherTypes')}
          />
          <span className="checkmark" />
        </label>
      );
    });
  const renderGenderInputs = (handleChange: any) =>
    genderInputs.map(({ label, type, name, value, id }, index) => {
      return (
        <label className="container" key={index}>
          {label}
          <Field type={type} className={'radio-btn'} id={id} name={name} value={value} onChange={handleChange(name)} />
          <span className="checkmark" />
        </label>
      );
    });
  const renderRadioInputs = (array: any[], handleChange: any) =>
    array.map(({ name, id, label, type }, index) => {
      return (
        <div key={index} className={'input__radio'}>
          <Field onChange={handleChange(name)} className={'radio-btn'} id={id} type={type} name={name} value={id} />
          <label className={'label'} htmlFor={id}>
            {label}
          </label>
        </div>
      );
    });

  return (
    <div className={'modal__wrapper'}>
      <Formik
        initialValues={filter}
        onSubmit={async (values: DashboardFilter) => {
          btnPressed();
          dispatch(actionFilterDataSet({ ...values, programme: filter.programme }));
          await loadData({ ...values, programme: filter.programme });
        }}
      >
        {({ handleChange }) => {
          return (
            <Form className={'modal__form'}>
              <div className={'modal__form_type'}>
                <h3>Type</h3>
                {renderRadioInputs(typeInputs, handleChange)}
                <h3>Age</h3>
                {renderRadioInputs(ageInputs, handleChange)}
              </div>
              <div className={'modal__form_gender'}>
                <h3>Gender</h3>
                {renderGenderInputs(handleChange)}
                <h3>Voucher Type</h3>
                {renderVoucherInputs(handleChange)}
              </div>
              <Button type={'submit'} title={'Apply'} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ModalFilter;
