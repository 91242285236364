import { totalActiveUsers } from '../AppUsage/QuickStats';
import { Document } from '../../types/data';
import { Gender } from '../../constants/filter';
import Events from '../../constants/events';
import { ComplianceData } from '../../types/compliance';

const filterByAgeAndSex = (users: Document[], gender: Gender, age: number[]) => {
  return users.filter((user) => {
    const filteredBySex = user.sex === gender;
    let filteredByAge;

    if (age[0] > 0 && age[1] > 0) {
      filteredByAge = user.age >= age[0] && user.age <= age[1];
    } else if (age[0] === 0 && age[1] > 0) {
      filteredByAge = user.age <= age[1];
    } else {
      filteredByAge = user.age >= age[0];
    }

    return filteredBySex && filteredByAge;
  });
};

export const calculateComplianceGraph = (data: ComplianceData, query: string, activeUsers: number) => {
  const statsArray = Object.keys(data);
  const filteredData = statsArray.filter((key) => key.includes(query));
  const totalNumber = filteredData.reduce((acc, value) => acc + data[value], 0);

  if (!totalNumber || !activeUsers) {
    return 0;
  }

  return Math.round(totalNumber / activeUsers) || 0;
};

export const generateGraphValues = (data: ComplianceData, query: string) => {
  const statsArray = Object.keys(data);
  const ageGroups = ['25', '50', '75', '99'];
  const maleValues = ageGroups.map((group) => {
    const value = statsArray.find((key) => {
      if (key[key.length - 1] === 'M' && key.includes(query) && key.includes(group)) {
        return key;
      }
    });

    return value ? data[value] : 0;
  });
  const femaleValues = ageGroups.map((group) => {
    const value = statsArray.find((key) => {
      if (key[key.length - 1] === 'F' && key.includes(query) && key.includes(group)) {
        return key;
      }
    });

    return value ? data[value] : 0;
  });

  return {
    maleValues,
    femaleValues,
  };
};

export const getFaceScansbyEvent = (users: Document[], event: Events) => {
  return users.filter((user) => user.event === event);
};

export const faceScanning = (users: Document[], event: Events, chronologicalAgeQuery?: 'lower' | 'higher') => {
  const totalScans = getFaceScansbyEvent(users, event);
  let filteredScans = totalScans;

  if (chronologicalAgeQuery) {
    switch (chronologicalAgeQuery) {
      case 'lower': {
        filteredScans = totalScans.filter((user) => user.bioage <= user.age);
        break;
      }

      case 'higher': {
        filteredScans = totalScans.filter((user) => user.bioage >= user.age);
        break;
      }
    }
  }

  return filteredScans.length;
};

export const faceScanGraph = (users: Document[], gender?: Gender, age?: number[]) => {
  const activeUsers = totalActiveUsers(users);
  const totalScans = getFaceScansbyEvent(users, Events.FaceScan);
  let filteredScans = totalScans;

  if (gender && age) {
    filteredScans = filterByAgeAndSex(totalScans, gender, age);
  }

  return Math.round(filteredScans.length / activeUsers.length) || 0;
};

export const generateFaceScanGraphValues = (users: Document[]) => {
  const ageGroups = [[0, 25], [25, 50], [50 - 75], [75, 0]];
  const maleValues = ageGroups.map((group) => faceScanGraph(users, Gender.Male, group));
  const femaleValues = ageGroups.map((group) => faceScanGraph(users, Gender.Female, group));

  return {
    maleValues,
    femaleValues,
  };
};
