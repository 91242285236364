import React, { LegacyRef } from 'react';
import './NavBar.scss';

interface Props {
  app_usage: boolean;
  compliance: boolean;
  content: boolean;
  behavior: boolean;
  app_ref: LegacyRef<HTMLDivElement>;
  compliance_ref: LegacyRef<HTMLDivElement>;
  content_ref: LegacyRef<HTMLDivElement>;
  behavior_ref: LegacyRef<HTMLDivElement>;
  app_btn_ref: LegacyRef<HTMLButtonElement>;
  compliance_btn_ref: LegacyRef<HTMLButtonElement>;
  content_btn_ref: LegacyRef<HTMLButtonElement>;
  behavior_btn_ref: LegacyRef<HTMLButtonElement>;
  setPort: (state: { app_usage: boolean; compliance: boolean; content: boolean; behavior: boolean }) => void;
  port: any;
}

const NavBar = ({
  app_usage,
  compliance,
  content,
  behavior,
  app_ref,
  content_ref,
  // behavior_ref,
  compliance_ref,
  // behavior_btn_ref,
  app_btn_ref,
  compliance_btn_ref,
  content_btn_ref,
  setPort,
  port,
}: Props) => {
  const scrollTo = (ref: any) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className={'navbar__wrapper'}>
      <button
        ref={app_btn_ref}
        onClick={() => {
          scrollTo(app_ref);
        }}
        className={compliance && app_usage ? 'link activeLink' : 'link'}
      >
        {' '}
        App usage
      </button>
      <button
        ref={compliance_btn_ref}
        onClick={() => {
          scrollTo(compliance_ref);
          setPort({ ...port, app_usage: false });
        }}
        className={!content && compliance && !app_usage ? 'link activeLink ' : 'link'}
      >
        Compliance
      </button>
      <button
        ref={content_btn_ref}
        onClick={() => {
          scrollTo(content_ref);
        }}
        className={(compliance && content) || (!compliance && !behavior && content) ? 'link activeLink ' : 'link'}
      >
        {' '}
        Content Engagement
      </button>
      {/*<button*/}
      {/*  ref={behavior_btn_ref}*/}
      {/*  onClick={() => {*/}
      {/*    scrollTo(behavior_ref);*/}
      {/*  }}*/}
      {/*  className={*/}
      {/* (!compliance && behavior && content) || (!compliance && !content && behavior) ? 'link activeLink ' : 'link'*/}
      {/*  }*/}
      {/*>*/}
      {/*  Behaviour change*/}
      {/*</button>*/}
    </div>
  );
};

export default NavBar;
