import React from 'react';
import VerticalBar from '../Chart/Vertical/VerticalBarChart';
import './ComplianceItem.scss';
import SVG_ICONS from '../../../assets/static/svg/svg';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { LoadingState } from '../../../types/loading';
import Skeleton from '../Skeleton/Skeleton';

interface Props {
  imgSrc?: string;
  title?: string;
  text?: string;
  number?: number;
  graphData: { maleValues: number[]; femaleValues: number[] };
  blockLoading: boolean;
}

const ComplianceItem: React.FC<Props> = ({ imgSrc, title, number, text, graphData, blockLoading }) => {
  const { isLoading } = useSelector<ApplicationState, LoadingState>((state) => state.loading);

  return (
    <div className={'compliance_wrapper'}>
      {isLoading || blockLoading ? (
        <Skeleton style={{ left: '50%', transform: 'translateX(-50%)' }} />
      ) : (
        <>
          <div className={'compliance_title'}>
            {imgSrc && <img src={imgSrc} alt={'load img'} />}
            <p className={'title'}> {title}</p>
            {!text && !number && (
              <div className={'gender_marker'}>
                <div className={'blue'}>
                  <img src={SVG_ICONS.DOTS_BLUE} alt={'blue circle'} />
                  <p>Male</p>
                </div>
                <div className={'red'}>
                  <img src={SVG_ICONS.DOTS_RED} alt={'red circle'} />
                  <p>Female</p>
                </div>
              </div>
            )}
          </div>

          <div className={'compliance_log_rate'}>
            <div className={'log_rate'}>
              <p>Av. log rate</p>
              <p className={'log_rate_info'}>
                {number} {text}
              </p>
            </div>
            {
              <div className={'gender_marker'}>
                <div className={'blue'}>
                  <img src={SVG_ICONS.DOTS_BLUE} alt={'blue circle'} />
                  <p>Male</p>
                </div>
                <div className={'red'}>
                  <img src={SVG_ICONS.DOTS_RED} alt={'red circle'} />
                  <p>Female</p>
                </div>
              </div>
            }
          </div>
          <div className={'compliance_chart'}>
            <VerticalBar graphData={graphData} />
          </div>
        </>
      )}
    </div>
  );
};

export default ComplianceItem;
