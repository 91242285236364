export const USER_JOURNEY_ACTION_TYPES = {
  SET_BTN_ACTIVE: 'SET_BTN_ACTIVE',
};

export const INTER_ACTION_ACTION_TYPES = {
  SET_ACTIVE: 'SET_ACTIVE',
};

export const APP_USAGE_TYPES = {
  USER_JOURNEY_TO_PERCENT: 'USER_JOURNEY_TO_PERCENT',
  GET_ALL_STATS: 'GET_ALL_STATS',
  SET_ACTIVE_FILTER_BUTTON: 'SET_ACTIVE_FILTER_BUTTON',
};
