import { Document } from '../../types/data';
import { averageDailyUsers, totalActiveUsers } from './QuickStats';
import { Programme } from '../../constants/filter';
import Events from '../../constants/events';

export const chunkRate = (users: Document[], goal?: Programme) => {
  const totalUsers = totalActiveUsers(users, goal).length;
  const averageUsers = averageDailyUsers(users, goal);

  if (!totalUsers) {
    return 0;
  }

  if (totalUsers === averageUsers) {
    return 100;
  }

  return ((totalUsers - averageUsers) / totalUsers) * 100;
};

export const journeyChurn = (users: Document[], event: Events, inPercent = true) => {
  const registeredUsers = users.filter((user) => user.event === Events.Registered);

  if (!registeredUsers.length) {
    return 0;
  }

  if (!inPercent) {
    return users.filter((user) => user.event === event).length;
  }

  return (users.filter((user) => user.event === event).length / registeredUsers.length) * 100;
};

export const journeyDataToPercent = (totalUsers: number, userWithCompletedEvent: number) => {
  return ((userWithCompletedEvent / totalUsers) * 100).toFixed();
};
