import React, { useState } from 'react';
import ComponentLayout, { selectedContext } from '../../components/_common/ComponentLayout/ComponentLayout';
import './Content.scss';
import MostPopularContentTypes from '../../components/_common/MostPopularContentTypes/MostPopularContentTypes';
import LineBar from '../../components/_common/MostPopularContentTypes/LineBar/LineBar';
import MostPopularContentItems from '../../components/_common/MostPopularContentItems/MostPopularContentItems';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import NoLog from '../../components/_common/NoLog/NoLog';
import { Content } from '../../types/contentTypes';
import { LoadingState } from '../../types/loading';
import Skeleton from '../../components/_common/Skeleton/Skeleton';

const MainContent = () => {
  const { data } = useSelector<ApplicationState, Content>((state) => state.content);
  const { isLoading } = useSelector<ApplicationState, LoadingState>((state) => state.loading);
  const [blockLoading, setBlockLoading] = useState(false);

  const noLogs = data[0].titleValue === 0 || data[0].titleValue === 0 || data[0].titleValue === 0;

  const renderTypes = () => {
    return data.map(({ title, data, titleValue }, index) => {
      return (
        <MostPopularContentTypes title={title} titleValue={titleValue} key={index}>
          {isLoading || blockLoading ? (
            <Skeleton style={{ left: '70%', transform: 'translateX(-70%)' }} />
          ) : (
            data.map(({ text, value }, index) => {
              return <LineBar key={index} text={text} value={value} />;
            })
          )}
        </MostPopularContentTypes>
      );
    });
  };

  return (
    <div className={'content'}>
      <ComponentLayout
        sectionName={'contentEngagement'}
        title={'Content Engagement'}
        daily={'daily'}
        weekly={'weekly'}
        full_program={'full programme'}
        setBlockLoading={setBlockLoading}
      >
        <div className={'content_types'}>
          <selectedContext.Consumer>
            {({ text }) => (
              <h3>
                Most popular content types
                {noLogs && <NoLog text={text} style={{ marginLeft: '12px' }} />}
              </h3>
            )}
          </selectedContext.Consumer>

          <div className={'content_types_items'}>
            <selectedContext.Consumer>{() => renderTypes()}</selectedContext.Consumer>
          </div>
        </div>
        <div className={'content_items'}>
          <h3>Most popular content items </h3>
          <MostPopularContentItems blockLoading={blockLoading} />
        </div>
      </ComponentLayout>
    </div>
  );
};

export default MainContent;
