import { ResContent } from '../../types/contentTypes';
import CONTENT_ACTION_TYPES from '../actionTypes/content';
import { HttpsCallableResult } from 'firebase/functions';

export function actionGetContent(data: ResContent) {
  return {
    type: CONTENT_ACTION_TYPES.SET_CONTENT,
    data: data,
  };
}

export default actionGetContent;
