export default function rangeValue(value: number) {
  switch (true) {
    case value <= 1:
      return value * 100;
    case value <= 100:
      return value;
    case value <= 1000:
      return value / 10;
    case value <= 10000:
      return value / 100;

    default:
      return value;
  }
}
