export enum Vouchers {
  AspirePartners = 'aspirePartners',
  AspirePepsi = 'aspirePepsi',
  AspirePepsiExtra = 'aspirePepsiExtra',
  AspireTesters = 'aspireTesters',
  AspireTestersExtra = 'aspireTestersExtra',
  India_partners = 'india_partners',
  India_pepsi_internal = 'india_pepsi_internal',
  India_testers = 'india_testers',
  India_external = 'india_external',
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
}

export enum Type {
  Overall = 'Overall',
  Healthy_bio_age = 'Healthy_bio_age',
  High_bio_age = 'High_bio_age',
}

export enum Programme {
  All = 'all',
  Nutrition = 'nutrition',
  Fitness = 'fitness',
  Sleep = 'sleep',
}

export enum AgeGroup {
  All = 'all',
  Less25 = 'less25',
  Between25_50 = 'between25_50',
  Between50_75 = 'between50_75',
  More75 = 'more75',
}
