import React from 'react';
import './MostPopularContentItems.scss';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Content } from '../../../types/contentTypes';
import Card from './Card/Card';
import NoLog from '../NoLog/NoLog';
import { selectedContext } from '../ComponentLayout/ComponentLayout';
import { LoadingState } from '../../../types/loading';
import Skeleton from '../Skeleton/Skeleton';

interface Props {
  blockLoading: boolean;
}

const MostPopularContentItems: React.FC<Props> = ({ blockLoading }) => {
  const { contentItems } = useSelector<ApplicationState, Content>((state) => state.content);
  const { isLoading } = useSelector<ApplicationState, LoadingState>((state) => state.loading);

  const recipes = contentItems.dataRecipes;
  const articles = contentItems.dataArticles;

  return (
    <div className={'content_items_wrapper'}>
      {isLoading || blockLoading ? (
        <Skeleton style={{ left: '60%', transform: 'translateX(-60%)' }} />
      ) : (
        <>
          <div className={'left_side'}>
            <h4>Recipes</h4>
            <selectedContext.Consumer>
              {({ text }) => {
                return recipes.length > 0 ? (
                  <div className={'left_side_content'}>
                    {recipes.map(({ label, text, title }, index) => {
                      return <Card key={index} label={label} title={title} text={text} />;
                    })}
                  </div>
                ) : (
                  <NoLog text={text} />
                );
              }}
            </selectedContext.Consumer>
          </div>

          <div className={'right_side'}>
            <h4>Articles</h4>
            <selectedContext.Consumer>
              {({ text }) => {
                return articles.length > 0 ? (
                  <div className={'right_side_content'}>
                    {articles.map(({ title, text, label }, index) => {
                      return <Card key={index} label={label} title={title} text={text} />;
                    })}
                  </div>
                ) : (
                  <NoLog text={text} />
                );
              }}
            </selectedContext.Consumer>
          </div>
        </>
      )}
    </div>
  );
};

export default MostPopularContentItems;
