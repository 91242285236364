import React from 'react';
import { Line } from 'rc-progress';
import './LineBar.scss';
import rangeValue from '../../../../constants/rangeValue';

interface Props {
  text: string;
  value: number;
}

const LineBar: React.FC<Props> = ({ text, value }) => {
  return (
    <div className={'lineBar__wrapper'}>
      <p className={'lineBar_text'}>{text}</p>
      <Line
        strokeColor={value === 0 ? '#E1E4F5' : '#3ACC86'}
        strokeWidth={4}
        trailWidth={4}
        trailColor={'#E1E4F5'}
        percent={rangeValue(value)}
        className={'line'}
      />
      <p className={'lineBar__wrapper_amount'} style={{ color: value === 0 ? '#9BA2C7' : '#1F264D' }}>
        {value}
      </p>
    </div>
  );
};

export default LineBar;
