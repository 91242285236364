import LOADING_ACTION_TYPES from '../actionTypes/loading';

function actionSetLoadingStatus(data: boolean) {
  return {
    type: LOADING_ACTION_TYPES.SET_IS_LOADING,
    data,
  };
}

export default actionSetLoadingStatus;
