import FILTER_ACTION_TYPES from '../../types/filter';
import { DashboardFilter } from '../../types/dashboard';

function actionFilterDataSet(data: DashboardFilter) {
  return {
    type: FILTER_ACTION_TYPES.SET_FILTER_DATA,
    data,
  };
}

export default actionFilterDataSet;
