import DATE_ACTION_TYPES from '../actionTypes/date';

export function actionInteractionTime(data: Date[] | null) {
  return {
    type: DATE_ACTION_TYPES.SET_INTERACTION_TIME_DATE,
    data,
  };
}

export function actionComplianceTime(data: Date[] | null) {
  return {
    type: DATE_ACTION_TYPES.SET_COMPLIANCE_DATE,
    data,
  };
}

export function actionContentTime(data: Date[] | null) {
  return {
    type: DATE_ACTION_TYPES.SET_CONTENT_ENGAGEMENT_DATE,
    data,
  };
}

export function actionBehaviourTime(data: Date[] | null) {
  return {
    type: DATE_ACTION_TYPES.SET_BEHAVIOUR_DATE,
    data,
  };
}
