import { actionInterface } from '../../types/common';
import FILTER_ACTION_TYPES from '../../types/filter';
import { defaultFilterInputState } from '../../constants/defaultData';
import { DashboardFilter } from '../../types/dashboard';

export default function filterReducer(
  state: DashboardFilter = defaultFilterInputState,
  { data, type }: actionInterface
) {
  switch (type) {
    case FILTER_ACTION_TYPES.SET_FILTER_DATA: {
      return { ...data };
    }
  }

  return state;
}
