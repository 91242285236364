import React, { useState } from 'react';
import './HeaderElement.scss';
import SVG_ICONS from '../../../../assets/static/svg/svg';
import Button from '../../Button/Button';
import { useDispatch } from 'react-redux';
import { actionSetJourneyDataToPercent } from '../../../../store/actions/appUsageAction';

interface Props {
  text: string;
  textClassName: string;
  children: any;
  icon?: boolean;
  alt?: string;
  value?: string;
  inputDate?: boolean;
  isPercent?: boolean;
}

const HeaderElement: React.FC<Props> = ({ text, textClassName, children, icon, alt, value, isPercent }) => {
  const [messageClosed, setMessageClosed] = useState(false);
  const dispatch = useDispatch();
  const viewInfo = () => {
    setMessageClosed(true);
  };

  const closeInfo = () => {
    setMessageClosed(false);
  };

  const message = (className: string) => {
    return <div className={className}> Time spent in application</div>;
  };

  return (
    <div className={'header_wrapper'}>
      <div className={'header_wrapper_left_side'}>
        <div className={textClassName}>
          <p>{text}</p>
          <div className={'interaction'}>
            {icon && (
              <>
                <img src={SVG_ICONS.INFO} alt={alt} onMouseEnter={viewInfo} onMouseLeave={closeInfo} />
                {messageClosed && message('message')}
              </>
            )}
          </div>
        </div>
        {value && (
          <Button
            className={'total_amount'}
            onClick={() => {
              dispatch(actionSetJourneyDataToPercent());
            }}
          >
            <span className={isPercent ? 'number' : 'percent'}>{value}</span>
            <span className={isPercent ? 'percent' : 'number'}>%</span>
          </Button>
        )}
      </div>
      <div className={'header_wrapper_right_side'}>{children}</div>
    </div>
  );
};

export default HeaderElement;
