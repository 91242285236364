import React from 'react';
import './NoLog.scss';

interface Props {
  text?: string;
  style?: {};
}

const NoLog: React.FC<Props> = ({ text, style }) => {
  return (
    <span className={'noLog'} style={style}>
      No logs during this {text}
    </span>
  );
};

export default NoLog;
