import React from 'react';
import { Circle } from 'rc-progress';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import rangeValue from '../../constants/rangeValue';
import { LoadingState } from '../../types/loading';
import Skeleton from '../_common/Skeleton/Skeleton';

const QuickStats = () => {
  const { quickStatsBlock } = useSelector((state: ApplicationState) => state.appUsage);
  const { isLoading } = useSelector<ApplicationState, LoadingState>((state) => state.loading);

  return (
    <div className={'quickStats__wrapper'}>
      <h3>{quickStatsBlock.header}</h3>
      <div className={'quickStats__items'}>
        {quickStatsBlock.data.map(({ text, value }, index) => {
          const isLast = index === quickStatsBlock.data.length - 1;

          return (
            <div key={index} className={'quickStats__item'}>
              {isLoading ? (
                <Skeleton width={80} height={80} />
              ) : (
                <>
                  <div className={'content'}>
                    {value === 0 ? (
                      <div className={'grey_line_wrapper'}>
                        <span className={'grey_line'} />
                      </div>
                    ) : (
                      <div className={'content__firstBlock'}>
                        {isLast && (
                          <Circle
                            percent={rangeValue(value)}
                            className={'quickStats__Circular'}
                            strokeColor={'#3ACC86'}
                            strokeWidth={15}
                            trailWidth={15}
                            trailColor={'#E1E4F5'}
                          />
                        )}

                        <p className={'content__top'}>
                          {isLast ? (value * 100).toFixed(1) : value}
                          {isLast && '%'}
                        </p>
                      </div>
                    )}
                    <p className={'content__down'}>{text}</p>
                  </div>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QuickStats;
