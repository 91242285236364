import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';

const decreaseStackedBar = (data: number[]) =>
  data.map((item: number, index: number) => {
    const barAmount = data.map((num) => (num === 0 ? 10 : num));

    return barAmount[index] - item;
  });

const options = {
  maintainAspectRatio: false,
  responsive: true,
  events: [],
  cornerRadius: 6,
  scales: {
    y: {
      title: {
        display: true,
        text: 'log rate',
        color: '#8087A8',
        font: {
          size: 12,
        },
      },
      ticks: {
        display: true,
        min: 0,
        max: 20,
        maxTicksLimit: 3,
        color: '#1F264D',
      },
      grid: {
        display: true,
        drawBorder: false,
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [4],
      },
    },
    x: {
      title: {
        display: true,
        padding: 0,
        text: 'age group (years)',
        color: '#8087A8',
        font: {
          size: 12,
        },
      },
      grid: {
        display: false,
        drawBorder: false,
        drawOnChartArea: true,
        drawTicks: false,
        borderDash: [4],
      },
      ticks: {
        padding: 10,
        color: '#1F264D',
      },
    },
    yAxes: {
      override: true,
      display: false,
      scaleLabel: {
        display: true,
      },
      ticks: {
        maxTicksLimit: 3,
        override: true,
        beginAtZero: true,
      },
    },
    xAxes: {
      display: false,
      scaleLabel: {
        display: false,
      },
      ticks: {
        display: false,
      },
    },
  },

  plugins: {
    legend: {
      display: false,
    },
  },
};

interface Props {
  graphData: { maleValues: number[]; femaleValues: number[] };
}

const VerticalBarChart: FC<Props> = ({ graphData }) => {
  const data = {
    labels: ['<25', '25-50', '50-75', '>75'],
    datasets: [
      {
        data: graphData.maleValues,
        stack: 'Male',
        backgroundColor: ['#002AFF'],
        borderRadius: 4,
        barPercentage: 0.7,
        categoryPercentage: 0.3,
      },
      {
        stack: 'Male',
        data: decreaseStackedBar(graphData.maleValues),
        backgroundColor: ['#E6E9F580'],
        borderRadius: 4,
        barPercentage: 0.7,
        categoryPercentage: 0.3,
      },
      {
        stack: 'Female',
        data: graphData.femaleValues,
        backgroundColor: ['#FF2567'],
        borderRadius: 4,
        barPercentage: 0.7,
        categoryPercentage: 0.3,
      },
      {
        stack: 'Female',
        data: decreaseStackedBar(graphData.femaleValues),
        backgroundColor: ['#E6E9F580'],
        borderRadius: 4,
        barPercentage: 0.7,
        categoryPercentage: 0.3,
      },
    ],
  };

  return <Bar data={data} options={options} redraw={true} />;
};

export default VerticalBarChart;
