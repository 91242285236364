import COMPLIANCE_ACTION_TYPES from '../actionTypes/compliance';
import { HttpsCallableResult } from 'firebase/functions';
import { ComplianceData } from '../../types/compliance';

export default function actionSetComplianceData(data: ComplianceData) {
  return {
    type: COMPLIANCE_ACTION_TYPES.SET_COMPLIANCE_DATA,
    data,
  };
}
