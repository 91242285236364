import React from 'react';
import ComponentLayout from '../../components/_common/ComponentLayout/ComponentLayout';
import './Behavior.scss';
import ComponentLayoutPrompts from '../../components/_common/ComponentLayoutPrompts/ComponentLayoutPrompts';
import ComponentLayoutEmail from '../../components/_common/ComponentLayoutEmail/ComponentLayoutEmail';

const BehaviorPage = () => {
  return (
    <div className="behavior">
      <ComponentLayout sectionName={'behaviour'} title={'Behaviour change'} full_program={'full programme'}>
        <ComponentLayoutPrompts />
        <ComponentLayoutEmail />
      </ComponentLayout>
    </div>
  );
};

export default BehaviorPage;
