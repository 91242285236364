import React from 'react';
import './Header.scss';
import useAuth from '../../../../hooks/useAuth';
import SVG_ICONS from '../../../../assets/static/svg/svg';
import Button from '../../Button/Button';

const Header = () => {
  const { logOut } = useAuth();

  return (
    <div className={'header__wrapper'}>
      <div className={'header__left'}>
        <img className={'header__left_logo'} src={SVG_ICONS.LOGO_MAIN} alt={'logo'} />
        <div className={'header__left__logoWrapper'}>
          <div className={'header__left__firstBlock'}>
            <h2>Data Dashboard</h2>
          </div>
          <div className={'header__left__secondBlock'}>
            <p>powered by</p>
            <img className={'secondBlock__symbol'} src={SVG_ICONS.SYMBOL} alt={'symbol'} />
            <img className={'secondBlock__SO'} src={SVG_ICONS.LOGO_SALUS_OPTIMA} alt={'SalusOptima'} />
          </div>
        </div>
      </div>
      <div className={'header__right'}>
        <Button>
          <img src={SVG_ICONS.LOGOUT} alt="logOut" onClick={logOut} />
        </Button>
      </div>
    </div>
  );
};

export default Header;
