import filter from './filter.svg';
import info from './Info_Outlined.svg';
import dots_blue from './dots_blue.svg';
import dots_red from './dots_red.svg';
import salus_optime from './salus_optime.svg';
import symbol from './symbol.svg';
import logout from './logout.svg';
import logo_main from './logo_main.svg';
import aspire_logo from './aspire_primary.svg';
import left from './chevron-left.svg';
import right from './chevron-right.svg';

const SVG_ICONS = {
  FILTER: filter,
  INFO: info,
  DOTS_BLUE: dots_blue,
  DOTS_RED: dots_red,
  LOGO_SALUS_OPTIMA: salus_optime,
  SYMBOL: symbol,
  LOGOUT: logout,
  LOGO_MAIN: logo_main,
  ASPIRE_PRIMARY: aspire_logo,
  LEFT: left,
  RIGHT: right,
};

export default SVG_ICONS;
