import React, { createContext, useState } from 'react';
import './ComponentLayout.scss';
import Button from '../Button/Button';
import CustomDatePicker from '../DatePicker/CustomDatePicker';
import { DateTypes } from '../../../types/date';
import { DashboardFilter } from '../../../types/dashboard';
import useFirebaseFunctions from '../../../hooks/useFirebaseFunctions';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { actionComplianceTime, actionContentTime } from '../../../store/actions/dateActions';
import { dateToLocalString } from '../../../Utils/UtilsCommon';

interface Props {
  title: string;
  children: React.ReactNode;
  daily?: string;
  weekly?: string;
  full_program?: string;
  date?: boolean;
  sectionName: keyof DateTypes;
  setBlockLoading?: (status: boolean) => void;
}

export const selectedContext = createContext({ text: '', activeButton: '' });

function ComponentLayout(props: Props) {
  const { title, children, daily, weekly, full_program, sectionName, setBlockLoading } = props;
  const [activeButton, setActiveButton] = useState('full_program');
  const text = activeButton === 'daily' ? 'day' : 'period';
  const { getComplianceStats, getContentStats } = useFirebaseFunctions();
  const dispatch = useDispatch();
  const { filter } = useSelector((state: ApplicationState) => state);
  const setHook = (section: string, filterData: DashboardFilter, date: Date[]) => {
    switch (section) {
      case 'compliance':
        if (setBlockLoading) {
          setBlockLoading(true);
          dispatch(actionComplianceTime(date));
          getComplianceStats(filterData).then(() => setBlockLoading(false));
        }

        break;

      case 'contentEngagement':
        if (setBlockLoading) {
          setBlockLoading(true);
          dispatch(actionContentTime(date));
          getContentStats(filterData).then(() => setBlockLoading(false));
        }

        break;
      default:
        return null;
    }
  };

  const clickHandler = (id: string) => {
    switch (id) {
      case 'daily':
        setHook(
          sectionName,
          { ...filter, startDate: dateToLocalString(new Date()), endDate: dateToLocalString(new Date()) },
          [new Date(), new Date()]
        );
        break;
      case 'weekly':
        const current = new Date();
        const pastDate = new Date(current);

        pastDate.setDate(pastDate.getDate() - 7);

        setHook(
          sectionName,
          { ...filter, startDate: dateToLocalString(pastDate), endDate: dateToLocalString(current) },
          [pastDate, current]
        );
        break;
      case 'full_program':
        setHook(sectionName, filter, [new Date('02/14/2022'), new Date()]);
        break;
    }

    setActiveButton(id);
  };

  return (
    <div className={'component_layout'}>
      <div className={'component_layout_header'}>
        <h2 className={'header_title'}>{title}</h2>
        <div className={'header_filters'}>
          <CustomDatePicker setBlockLoading={setBlockLoading} sectionName={sectionName} interval={activeButton} />
          <div className={'button_wrapper_daly'}>
            {daily && (
              <Button
                id={'daily'}
                className={activeButton === 'daily' ? 'white active' : 'white'}
                title={daily}
                onClickWithEvent={(e) => clickHandler(e.currentTarget.id)}
              />
            )}
            {weekly && (
              <Button
                id={'weekly'}
                className={activeButton === 'weekly' ? 'white active' : 'white'}
                title={weekly}
                onClickWithEvent={(e) => clickHandler(e.currentTarget.id)}
              />
            )}
            {full_program && (
              <Button
                id={'full_program'}
                className={activeButton === 'full_program' ? 'white active' : 'white'}
                title={full_program}
                onClickWithEvent={(e) => clickHandler(e.currentTarget.id)}
              />
            )}
          </div>
        </div>
      </div>
      <selectedContext.Provider value={{ text, activeButton }}>{children}</selectedContext.Provider>
    </div>
  );
}

export default ComponentLayout;
