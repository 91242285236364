import React from 'react';
import { Circle } from 'rc-progress';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { Behavior } from '../../../types/behavior';

const ComponentLayoutEmail = () => {
  const { email } = useSelector<ApplicationState, Behavior>((state) => state.behavior);
  const test = false;

  return (
    <div className={'component_layout_email'}>
      <h3>Email engagement</h3>
      <div className={'email-wrapper'}>
        {email.map(({ percentage, description }, index) => {
          return (
            <div key={index} className={'email-wrapper__item'}>
              <div className={'email-wrapper__item-chart'}>
                {test ? (
                  <div className={'email-wrapper__empty'}>
                    <p>-</p>
                  </div>
                ) : (
                  <div className={'email-wrapper__item-chart_wrapper'}>
                    <Circle
                      percent={percentage}
                      className={'quickStats__Circular'}
                      strokeColor={'#3ACC86'}
                      strokeWidth={15}
                      trailWidth={15}
                      trailColor={'#E1E4F5'}
                    />
                    <p className={'email-wrapper__item-chart_title'}>{percentage}%</p>
                  </div>
                )}
              </div>
              <p className={'email-wrapper__item-description'}>{description}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ComponentLayoutEmail;
