import { getFunctions, httpsCallable, HttpsCallableResult } from 'firebase/functions';
import { DashboardFilter, UpdatedData } from '../types/dashboard';
import { useDispatch } from 'react-redux';
import actionSetComplianceData from '../store/actions/complianceAction';
import { ComplianceData } from '../types/compliance';
import actionGetContent from '../store/actions/contentAction';
import { ResContent } from '../types/contentTypes';
import { actionSetAppUsageStats } from '../store/actions/appUsageAction';
import { AppUsageData } from '../types/appUsage';
import actionSetLoadingStatus from '../store/actions/loadingActions';

function useFirebaseFunctions() {
  const dispatch = useDispatch();
  const functions = getFunctions();
  const getAppUsage = httpsCallable<DashboardFilter, AppUsageData>(functions, 'callableAppUsage', {
    timeout: 120000,
  });
  const getCompliance = httpsCallable<DashboardFilter, ComplianceData>(functions, 'callableCompliance', {
    timeout: 120000,
  });
  const getContent = httpsCallable<DashboardFilter, ResContent>(functions, 'callableContent', {
    timeout: 120000,
  });
  const updatedGetContent = httpsCallable<DashboardFilter, UpdatedData>(functions, 'callablePepsiDashboardContent', {
    timeout: 120000,
  });

  const getAppUsageStats = async (payload: DashboardFilter) => {
    const res: HttpsCallableResult<AppUsageData> = await getAppUsage(payload);
    const { data } = { ...res };

    dispatch(actionSetAppUsageStats(data));

    return res;
  };

  const getComplianceStats = async (payload: DashboardFilter) => {
    const res: HttpsCallableResult<ComplianceData> = await getCompliance(payload);

    const { data } = { ...res };

    dispatch(actionSetComplianceData(data));

    return res;
  };

  const getContentStats = async (payload: DashboardFilter) => {
    const res = await getContent(payload);
    const { data } = { ...res };

    dispatch(actionGetContent(data));

    return res;
  };

  const getUpdatedContent = async (payload: DashboardFilter) => {
    const result: HttpsCallableResult<UpdatedData> = await updatedGetContent(payload);
    const {
      data: { appUsage, compliance, content },
    } = { ...result };

    dispatch(actionSetAppUsageStats(appUsage));
    dispatch(actionSetComplianceData(compliance));
    dispatch(actionGetContent(content));

    return result;
  };

  return {
    getAppUsageStats,
    getComplianceStats,
    getContentStats,
    getUpdatedContent,
  };
}

export default useFirebaseFunctions;
