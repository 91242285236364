import React, { useEffect, useRef, useState } from 'react';
import Header from '../Headers/HeaderSIte/Header';
import NavBar from '../../NavBar/NavBar';
import './Layout.scss';
import AppUsage from '../../../pages/AppUsage/AppUsage';
import { InView } from 'react-intersection-observer';
import Compliance from '../../../pages/Compilance/Compliance';
import Content from '../../../pages/Content/Content';
// import Behavior from '../../../pages/Behavior/Behavior';
import Button from '../Button/Button';
import SVG_ICONS from '../../../assets/static/svg/svg';
import ModalFilter from '../../ModalFilter/ModalFilter';
import { defaultFilterInputState } from '../../../constants/defaultData';
import useData from '../../../hooks/useData';

const Layout: React.FC = () => {
  const [inViewPort, setInViewPort] = useState({
    app_usage: true,
    compliance: false,
    content: false,
    behavior: false,
  });
  const { app_usage, content, compliance, behavior } = inViewPort;
  const appRef = useRef<null | HTMLDivElement>(null);
  const complianceRef = useRef<null | HTMLDivElement>(null);
  const contentRef = useRef<null | HTMLDivElement>(null);
  const behaviorRef = useRef<null | HTMLDivElement>(null);
  const appBtnRef = useRef<null | HTMLButtonElement>(null);
  const complianceBtnRef = useRef<null | HTMLButtonElement>(null);
  const contentBtnRef = useRef<null | HTMLButtonElement>(null);
  const behaviorBtnRef = useRef<null | HTMLButtonElement>(null);
  const [isPressed, setPressed] = useState(false);
  const { loadData } = useData();

  function btnPressed() {
    setPressed(!isPressed);
  }

  useEffect(() => {
    loadData(defaultFilterInputState);
  }, []);

  return (
    <div className={'wrapper'}>
      <div className={'layout__wrapper'}>
        <Header />

        <div className={'content__wrapper'}>
          <NavBar
            app_btn_ref={appBtnRef}
            compliance_btn_ref={complianceBtnRef}
            content_btn_ref={contentBtnRef}
            behavior_btn_ref={behaviorBtnRef}
            behavior_ref={behaviorRef}
            compliance_ref={complianceRef}
            content_ref={contentRef}
            app_ref={appRef}
            content={content}
            app_usage={app_usage}
            behavior={behavior}
            compliance={compliance}
            setPort={setInViewPort}
            port={inViewPort}
          />
          <div className={'general_content'}>
            <header className={'appUsage__header'}>
              <p>
                {compliance && app_usage && 'App Usage'}
                {!content && compliance && !app_usage && 'Compliance'}
                {(compliance && content) || (!compliance && !behavior && content) ? 'Content Engagement ' : ''}
                {(!compliance && behavior && content) || (!compliance && !content && behavior)
                  ? 'Behaviour change '
                  : ''}
              </p>
              <div style={{ backgroundColor: isPressed ? '#E1E4F5' : '#ffffff' }} className={'modal__div'}>
                <Button className={'header__filter__btn'} onClick={btnPressed}>
                  <img src={SVG_ICONS.FILTER} alt={'img'} />
                  <p>FILTERS</p>
                </Button>
                {isPressed && <ModalFilter btnPressed={btnPressed} />}
              </div>
            </header>
            <InView
              onChange={(inView) => {
                setInViewPort({ ...inViewPort, app_usage: inView });
              }}
            >
              <div ref={appRef}>
                <AppUsage />
              </div>
            </InView>
            <InView
              onChange={(inView) => {
                setInViewPort({ ...inViewPort, compliance: inView });
              }}
            >
              <div ref={complianceRef}>
                <Compliance />
              </div>
            </InView>
            <InView
              onChange={(inView) => {
                setInViewPort({ ...inViewPort, content: inView });
              }}
            >
              <div ref={contentRef}>
                <Content />
              </div>
            </InView>
            {/*<InView*/}
            {/*  onChange={(inView) => {*/}
            {/*    setInViewPort({ ...inViewPort, behavior: inView });*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <div ref={behaviorRef}>*/}
            {/*    <Behavior />*/}
            {/*  </div>*/}
            {/*</InView>*/}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
