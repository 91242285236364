import React from 'react';
import './Card.scss';

interface Props {
  label?: string;
  title?: string;
  text?: string;
}

const Card: React.FC<Props> = ({ label, title, text }) => {
  return (
    <div className={'card_wrapper'}>
      <div className={'card_image'}>
        <div className={'image_top'}>
          <p>
            {label} {}
          </p>
        </div>
      </div>
      <div className={'card_context'}>
        <div className={'context_top'}>
          <h5>{title}</h5>
        </div>
        <div className={'context_center'}>
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
