import BEHAVIOR_ACTION_TYPES from '../actionTypes/behavior';
import { Behavior } from '../../types/behavior';

export interface CustomAction {
  type: string;
  data?: any;
}

const defaultState: Behavior = {
  notifications: [
    '1. Start of notification copy....',
    '2. Start of notification copy....',
    '3. Start of notification copy....',
  ],
  notificationsValues: [
    {
      title: 'Sent',
      value: 0,
    },
    {
      title: 'Clicked on',
      value: 0,
    },
  ],
  prompts: ['1. Card question', '2. Card question', '3. Card question'],
  promptsValues: [
    {
      title: 'Sent',
      value: 0,
    },
    {
      title: 'Answered',
      value: 0,
    },
  ],
  email: [
    {
      description: 'Open Rate',
      percentage: 0,
    },
    {
      description: 'Bounce rate',
      percentage: 0,
    },
    {
      description: 'Unsubscribe rate',
      percentage: 0,
    },
  ],
};

export default function behaviorReducer(state: Behavior = defaultState, action: CustomAction) {
  switch (action.type) {
    case BEHAVIOR_ACTION_TYPES.GET_DATA:
      return {
        ...state,
        notification: action.data,
      };
  }

  return defaultState;
}
