import React from 'react';
import './AppUsage.scss';
import '../../components/QuickStats/QuickStats.scss';
import QuickStats from '../../components/QuickStats/QuickStats';
import UserJourney from '../../components/UserJourney/UserJourney';

const AppUsage = () => {
  return (
    <div className={'appUsage__wrapper'}>
      <QuickStats />
      {/*<Churn />*/}
      <UserJourney activation={'activation_churn'} program={'program_churn'} />
      {/*<InterActionTime weekly={'weekly'} full_program={'full_programme'} inputDate={true} />*/}
    </div>
  );
};

export default AppUsage;
