import React, { ChangeEvent, FormEvent, useState } from 'react';
import './LogInForm.scss';
import { Link } from 'react-router-dom';
import Button from '../_common/Button/Button';
import useAuth from '../../hooks/useAuth';

const LogInForm = () => {
  const { loginWithEmailAndPassword } = useAuth();
  const [values, setValues] = useState({ email: '', password: '' });
  const buttonIsDisabled = !values.password || !values.email;
  const { email, password } = values;
  const submitHandler = async () => {
    await loginWithEmailAndPassword(email, password);
  };

  function changeInputValue(value: string, name: string) {
    setValues({ ...values, [name]: value });
  }

  return (
    <form
      className={'form__wrapper'}
      onSubmit={(event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setValues({ email: '', password: '' });

        return submitHandler();
      }}
    >
      <div>
        <h1>Login</h1>
      </div>
      <div className={'form__inputArea'}>
        <input
          placeholder={'Email'}
          type={'email'}
          name={'email'}
          value={email}
          maxLength={35}
          onChange={(event: ChangeEvent<HTMLInputElement>) => changeInputValue(event.target.value, event.target.name)}
        />
        <input
          placeholder={'Password'}
          type={'password'}
          name={'password'}
          maxLength={35}
          value={password}
          onChange={(event: ChangeEvent<HTMLInputElement>) => changeInputValue(event.target.value, event.target.name)}
        />
      </div>
      <Button type={'submit'} disabled={buttonIsDisabled}>
        Log in
      </Button>
      {/*<div>*/}
      {/*  <Link to={'#'}>Forgot password? </Link>*/}
      {/*</div>*/}
    </form>
  );
};

export default LogInForm;
