import React from 'react';
import './MostPopularContentTypes.scss';

interface Props {
  title?: string;
  children?: React.ReactNode;
  titleValue?: number;
}

const MostPopularContentTypes: React.FC<Props> = ({ title, children }) => {
  return (
    <div className={'content_types_wrapper'}>
      <h4>
        {title} {}
        {/*<span style={{ color: titleValue === 0 ? '#9BA2C7' : '#1F264D' }}></span>*/}
      </h4>
      {children}
    </div>
  );
};

export default MostPopularContentTypes;
