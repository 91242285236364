import { useHistory } from 'react-router-dom';
import { signOut } from 'firebase/auth';
import Routes from '../config/routes/routes';
import USER_TOKEN_NAMESPACE from '../constants/namespace';
import LocalStorage from '../services/storage';
import { auth } from '../config/firebase';

function useAuth() {
  const history = useHistory();

  const loginWithEmailAndPassword = async (email: string, password: string) => {
    try {
      /* PROCESS HOLD - LOGIN WITH FIREBASE
      await signInWithEmailAndPassword(auth, email, password).then((res) => {
        getIdToken(res.user).then((token) => {
          LocalStorage.set(USER_TOKEN_NAMESPACE, token);
          history.push(Routes.APP_USAGE);
        });
      });*/

      if (email === 'pepsi_admin@salusoptima.com' && password === 'h23h498uf98h') {
        history.push(Routes.APP_USAGE);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const logOut = async () => {
    await signOut(auth);
    history.push(Routes.LOGIN);
    LocalStorage.set(USER_TOKEN_NAMESPACE, '');
  };

  return {
    loginWithEmailAndPassword,
    logOut,
  };
}

export default useAuth;
