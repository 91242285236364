enum Events {
  Registered = 'Registered',
  OpenedApp = 'OpenedApp',
  StartedOnboarding1 = 'StartedOnboarding1',
  CompletedOnboarding1 = 'CompletedOnboarding1',
  FaceScan = 'FaceScan',
  StartedOnboarding2 = 'StartedOnboarding2',
  CompletedOnboarding2 = 'CompletedOnboarding2',
  PromptedFaceScan = 'PromptedFaceScan',
  ExtraFaceScan = 'ExtraFaceScan',
  OpenedTip = 'OpenedTip',
  OpenedArticle = 'OpenedArticle',
  OpenedChallenge = 'OpenedChallenge',
  Week1Interation = 'Week1Interation',
  Week1Completion = 'Week1Completion',
  Week2Interation = 'Week2Interation',
  Week2Completion = 'Week2Completion',
  Week3Interation = 'Week3Interation',
  Week3Completion = 'Week3Completion',
  Week4Interation = 'Week4Interation',
  Week4Completion = 'Week4Completion',
  StartedOffboarding = 'StartedOffboarding',
  FinishedOffboarding = 'FinishedOffboarding',
  StartedFeedback = 'StartedFeedback',
  FinishedFeedback = 'FinishedFeedback',
}

export default Events;
