import React from 'react';
import './LineBarItem.scss';
import { Line } from 'rc-progress';
import { journeyDataToPercent } from '../../../Utils/AppUsage/Churn';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';

interface Props {
  title: string;
  value: number;
  isPercent: boolean;
}
const LineBarItem: React.FC<Props> = ({ title, value, isPercent }) => {
  const totalUsers = useSelector((state: ApplicationState) => state.appUsage.quickStatsBlock.data[0]);
  const renderValue = () => {
    if (isPercent) {
      return journeyDataToPercent(totalUsers.value, value);
    } else {
      return value;
    }
  };

  return (
    <div className={'lineBarItem__wrapper'}>
      <div className={'mobile_left'}>
        <p className={'title'}>{title}</p>
      </div>
      <div className={'mobile_right'}>
        <Line
          strokeColor={value === 0 ? '#E1E4F5' : '#3ACC86'}
          strokeWidth={2}
          trailWidth={2}
          trailColor={'#E6E9F5'}
          percent={+renderValue()}
          strokeLinecap={'round'}
          className={'lineBar'}
        />
        <p style={{ color: value === 0 ? '#9BA2C7' : '#1F264D' }}>
          {' '}
          {renderValue()} {isPercent && <span>%</span>}
        </p>
      </div>
    </div>
  );
};

export default LineBarItem;
