import React, { useState } from 'react';
import Button from '../_common/Button/Button';
import './Filters.scss';
import { Programme } from '../../constants/filter';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../store';
import { appUsage } from '../../types/appUsage';
import { actionSetActiveFilterButton } from '../../store/actions/appUsageAction';
import useFirebaseFunctions from '../../hooks/useFirebaseFunctions';
import { DashboardFilter } from '../../types/dashboard';
import actionFilterDataSet from '../../store/actions/filterAction';

const buttons = [
  {
    title: 'total',
    value: Programme.All,
  },
  {
    title: 'fitness',
    value: Programme.Fitness,
  },
  {
    title: 'nutrition',
    value: Programme.Nutrition,
  },
  {
    title: 'sleep',
    value: Programme.Sleep,
  },
];

interface Props {
  setIsLoading: (status: boolean) => void;
}

const Filters: React.FC<Props> = ({ setIsLoading }) => {
  const dispatch = useDispatch();
  const { userJourney } = useSelector<ApplicationState, appUsage>((state) => state.appUsage);
  const { filter } = useSelector((state: ApplicationState) => state);
  const { getAppUsageStats } = useFirebaseFunctions();
  const [activeButton, setActiveButton] = useState(userJourney.activeBtn);
  const clickHandler = async (value: Programme) => {
    setIsLoading(true);
    setActiveButton(value);
    dispatch(actionSetActiveFilterButton(value));
    dispatch(actionFilterDataSet({ ...filter, programme: value }));
    await getAppUsageStats({ ...filter, programme: value }).then(() => setIsLoading(false));
  };

  return (
    <div className={'filters__wrapper'}>
      <h3> Filters:</h3>

      {buttons.map((button, index) => (
        <Button
          key={index}
          title={button.title}
          value={button.value}
          onClick={() => clickHandler(button.value)}
          className={activeButton === button.value ? 'active_filters_btn' : 'filters_btn'}
        />
      ))}
    </div>
  );
};

export default Filters;
