import React from 'react';
import DatePicker from 'react-datepicker';
import './CustomDatePicker.scss';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../store';
import { DateTypes } from '../../../types/date';
import {
  actionBehaviourTime,
  actionComplianceTime,
  actionContentTime,
  actionInteractionTime,
} from '../../../store/actions/dateActions';
import useFirebaseFunctions from '../../../hooks/useFirebaseFunctions';
import { dateToLocalString } from '../../../Utils/UtilsCommon';
import 'react-datepicker/dist/react-datepicker.css';
import SVG_ICONS from '../../../assets/static/svg/svg';
import Button from '../Button/Button';

interface Props {
  interval: string;
  sectionName: keyof DateTypes;
  setBlockLoading?: (status: boolean) => void;
}

function CustomDatePicker(Props: Props) {
  const { interval, sectionName, setBlockLoading } = Props;
  const dispatch = useDispatch();
  const isDisabled = interval === 'full_program';

  const sectionDate = useSelector((state: ApplicationState) => state.date[sectionName]);
  const { getComplianceStats, getContentStats } = useFirebaseFunctions();
  const { filter } = useSelector((state: ApplicationState) => state);

  const onChange = (date: any) => {
    switch (sectionName) {
      case 'interactionTime': {
        dispatch(actionInteractionTime(date));
        break;
      }

      case 'compliance': {
        dispatch(actionComplianceTime(date));
        break;
      }

      case 'contentEngagement': {
        dispatch(actionContentTime(date));
        break;
      }

      case 'behaviour': {
        dispatch(actionBehaviourTime(date));
        break;
      }
    }
  };

  const onClose = () => {
    switch (sectionName) {
      case 'compliance': {
        if (Array.isArray(sectionDate) && setBlockLoading) {
          setBlockLoading(true);
          getComplianceStats({
            ...filter,
            startDate: dateToLocalString(sectionDate[0]),
            endDate: dateToLocalString(sectionDate[1]),
          }).then(() => setBlockLoading(false));
        }

        break;
      }

      case 'contentEngagement': {
        if (Array.isArray(sectionDate) && setBlockLoading) {
          setBlockLoading(true);
          getContentStats({
            ...filter,
            startDate: dateToLocalString(sectionDate[0]),
            endDate: dateToLocalString(sectionDate[1]),
          }).then(() => setBlockLoading(false));
        }

        break;
      }
    }
  };

  const generateNumberOfDays = (period: 'day' | 'week') => (period === 'day' ? 1 : 7);

  const generateNewDate = (previousDate: Date[], arrowDirection: 'left' | 'right', period: 'day' | 'week') =>
    previousDate.map((date) => {
      const newDate = date;

      newDate.setDate(
        arrowDirection === 'left'
          ? newDate.getDate() - generateNumberOfDays(period)
          : newDate.getDate() + generateNumberOfDays(period)
      );

      return newDate;
    });

  const onArrowClick = (arrowDirection: 'left' | 'right', period: 'day' | 'week') => {
    const previousDate = sectionDate;

    if (Array.isArray(previousDate) && setBlockLoading) {
      switch (sectionName) {
        case 'compliance': {
          const newDate = generateNewDate(previousDate, arrowDirection, period);

          dispatch(actionComplianceTime(newDate));
          setBlockLoading(true);
          getComplianceStats({
            ...filter,
            startDate: dateToLocalString(newDate[0]),
            endDate: dateToLocalString(newDate[1]),
          }).then(() => setBlockLoading(false));
          break;
        }

        case 'contentEngagement': {
          const newDate = generateNewDate(previousDate, arrowDirection, period);

          dispatch(actionComplianceTime(newDate));
          setBlockLoading(true);
          getContentStats({
            ...filter,
            startDate: dateToLocalString(newDate[0]),
            endDate: dateToLocalString(newDate[1]),
          }).then(() => setBlockLoading(false));
          break;
        }
      }
    }
  };

  if (interval !== 'daily' && sectionDate) {
    return (
      <div className={'custom-range-picker'}>
        <DatePicker
          selectsRange={true}
          disabled={interval === 'full_program'}
          onCalendarClose={onClose}
          startDate={sectionDate[0]}
          minDate={new Date('02/14/2022')}
          maxDate={new Date()}
          endDate={sectionDate[1]}
          onChange={(date) => {
            if (date) {
              onChange(date);
            }
          }}
        />
        <Button onClick={() => onArrowClick('left', 'week')} disabled={isDisabled} className="arrow_left">
          <img src={SVG_ICONS.LEFT} />
        </Button>
        <Button onClick={() => onArrowClick('right', 'week')} disabled={isDisabled} className="arrow_right">
          <img src={SVG_ICONS.RIGHT} />
        </Button>
      </div>
    );
  }

  if (sectionDate) {
    return (
      <div className={'custom-range-picker'}>
        <DatePicker
          minDate={new Date('02/14/2022')}
          maxDate={new Date()}
          disabled={isDisabled}
          dateFormat="yyyy/MM/dd"
          onCalendarClose={onClose}
          selected={sectionDate[0]}
          onChange={(date) => {
            if (date) {
              onChange(date);
            }
          }}
        />
        <Button onClick={() => onArrowClick('left', 'day')} disabled={isDisabled} className="arrow_left">
          <img src={SVG_ICONS.LEFT} />
        </Button>
        <Button onClick={() => onArrowClick('left', 'day')} disabled={isDisabled} className="arrow_right">
          <img src={SVG_ICONS.RIGHT} />
        </Button>
      </div>
    );
  }

  return <></>;
}

export default CustomDatePicker;
